@import url("./css/bootstrap.min.css");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;700&family=Roboto+Slab:wght@400;700&display=swap");

*,
html,
body {
  font-family: "Ubuntu", sans-serif;
  font-weight: 300;
  scroll-behavior: smooth;
}

.fw-light,
.fw-200 {
  font-weight: 300;
}
.fw-regular,
.fw-400 {
  font-weight: 400;
}

.fw-bold,
.fw-800 {
  font-weight: 700;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto Slab", serif;
}

.logo {
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: 999;
  height: 72px;
  width: 72px;
  border-radius: 0.5rem;
}

.hero-gradient {
  background-image: linear-gradient(
    to right,
    rgb(255, 228, 230),
    rgb(204, 251, 241)
  );
}
.bottom-fade {
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40vh;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1) 100%
  );
  z-index: 1;
}

.h-75vh {
  height: 75vh;
}

.mb--1 {
  margin-bottom: -4px;
}
.mb--2 {
  margin-bottom: -8px;
}
.z-index-1 {
  z-index: 2;
}

@media (max-width: 991.98px) {
  .display-1 {
    font-size: 3rem;
    line-height: 1.2;
  }
  .display-4 {
    font-size: 2rem;
    line-height: 1.2;
  }
  .display-5 {
    font-size: 1.5rem;
    line-height: 1;
  }

  .mt--5 {
    margin-top: -7.5rem;
  }

  .logo {
    top: 1rem;
    left: 1rem;
  }
}

.opacity-85 {
  opacity: 0.9;
}
.opacity-50 {
  opacity: 0.7;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.overflow-hidden {
  overflow: hidden;
}

.justify-content-space-between {
  justify-content: space-between;
}
